/* 
 * .sr-only
 * 
 * good way to make something accesible to screen readers, but not visible 
*/

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

/* Only show on desktop */
.desktop {
  @media (--mobile-only) {
    display: none;
  }
}

/* Only show on mobile */
.mobile {
  @media (--small) {
    display: none;
  }
}
