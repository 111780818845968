:root {
  /* page size and layout variables */
  --page-width: 4800px;
  --section-width: 100%;
  --text-width: 806px;

  /* icon variables */
  --icon-xs: var(--spacing-12);
  --icon-sm: var(--spacing-16);
  --icon-md: var(--spacing-24);
  --icon-lg: var(--spacing-32);

  /* pdp add to favourite + add to cart button */
  --pdp-button-border: #d5d0be;
  --pdp-button-height: 37px;
  --pdp-button-color-inactive: var(--neutral-10);
  --pdp-button-size-inactive: var(--neutral-11);

  /* buttons height in PLP filter and sort-by modal */
  --plp-button-height: 44px;

  /* tags */
  --tags-background-color: var(--neutral-2);
  --color-inactive-tag: var(--neutral-6-2);

  /* radio buttons ::before and ::after elements */
  --radio-outer-size: 24px;
  --radio-inner-size: calc(var(--radio-outer-size) - 6px);

  /* swatches */
  --swatch-outer-size: 18px;
  --swatch-inner-size: calc(var(--swatch-outer-size) - 6px);
  --swatch-border: 1px solid #ddd;
  --swatch-default-color: var(--neutral-6);

  /* z-index variables */
  --z-overlay: 100;
  --z-sticky: 50;
  --z-behind: -1;
  --z-above: 1;

  /* header variables */
  --snackbar-height: 32px;
  --header-offset-mobile: 10px;
  --header-height-scroll: 48px;
  --header-height: 63px;
  --header-transition-speed: 250ms;
  --_home-header-padding: 0px;
  /*
  @media (--small) {
    --header-height: 72px;
  }

  @media (--large) {
    --header-height: 92px;
  }
  */
  /* accordion variables */
  --accordion-spacing: var(--spacing-12);
  --border-radius-xs: 4px;

  --header-extra-click-area: 25px;
}
