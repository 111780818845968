@import "swiper/css";

body {
  --arrow-size: 24px;
  /* body to increase specificity */

  & .swiper {
    padding-bottom: calc(var(--spacing-16) + var(--arrow-size));
  }

  & .swiper-button-prev,
  & .swiper-button-next {
    position: absolute;
    background-color: var(--color-tertiary);
    border-radius: 100%;
    display: flex;
    align-items: center;
    width: var(--arrow-size);
    height: var(--arrow-size);
    --swiper-navigation-size: 14px;
    color: var(--color-primary);
    transition: opacity 250ms;
    top: auto;
    bottom: 0;

    &:hover {
      background: var(--neutral-2);
    }

    &::after {
      content: "";
      background-image: url("/images/ui-more-right.svg");
      background-size: 16px;
      width: 16px;
      height: 16px;
      display: block;
    }
  }

  & .swiper-button-next {
    right: 0;
  }

  & .swiper-button-prev {
    left: auto;
    right: calc(0px + var(--arrow-size) + var(--spacing-md));

    &::after {
      background-image: url("/images/ui-more-left.svg");
    }
  }

  & .swiper-button-next.swiper-button-disabled,
  & .swiper-button-prev.swiper-button-disabled,
  & .swiper:hover .swiper-button-next.swiper-button-disabled,
  & .swiper:hover .swiper-button-prev.swiper-button-disabled {
    opacity: 0.5;
  }

  & .swiper-pagination-bullets {
    display: flex;
    position: absolute;
    left: calc(var(--arrow-size) + var(--spacing-md));
    bottom: calc(var(--arrow-size) / 2 - 2px);
    width: calc(100% - var(--arrow-size) * 3 - var(--spacing-md) * 3);
    justify-content: center;
  }

  & .swiper-pagination-bullet {
    width: 100%;
    height: 2px;
    background: hsl(0deg 0% 89.02%);
    display: block;
    transition: all 200ms linear;

    &.swiper-pagination-bullet-active {
      background: hsl(0deg 0% 8.63%);
    }
  }

  & .swiper-slide {
    opactiy: 0;
    transition: all 800ms;
    margin-inline: 1px;
    &.swiper-slide-active {
      opacity: 1;
    }
  }

  & .swiperZoom {
    padding: 0 !important;
    --controler-width: min(543px, calc(100vw - var(--page-inset) * 2));
    --controler-padding: 20px;
    --controler-bottom: 20px;

    & .swiper-pagination-bullets {
      background: #fff;
      border-radius: 200px;
      padding: var(--controler-padding) 152px var(--controler-padding) 86px;
      bottom: var(--controler-bottom);
      z-index: 97;
      width: calc(100vw - var(--page-inset) * 2);
      max-width: var(--controler-width);
      left: 50%;
      transform: translateX(-50%);
    }
    & .swiper-button-prev,
    & .swiper-button-next {
      bottom: calc(var(--controler-bottom) + var(--controler-padding) / 2);
      left: 50%;
      right: auto;
      z-index: 98;
      transform: translateX(
        calc(var(--controler-width) / 2 - 24px - var(--arrow-size))
      );
    }

    & .swiper-button-prev {
      transform: translateX(
        calc(var(--controler-width) / 2 - 24px - var(--arrow-size) * 3)
      );
    }

    & .swiperCount {
      bottom: calc(var(--controler-bottom) + var(--controler-padding) / 2);
      left: 50%;
      z-index: 98;
      transform: translateX(calc((0px - var(--controler-width)) / 2 + 24px));
    }
  }

  @media (--md) {
    --controler-bottom: 40px;
  }
}
