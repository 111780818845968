.ExitPreviewMode_root__s5AMV {
  position: fixed;
  bottom: var(--spacing-md);
  left: 0;
  background-color: black;
  color: white;
  --svg-color: white;
  border: none;
  height: 40px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  overflow: hidden;
  max-width: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: var(--spacing-xs);
  -webkit-transition: max-width 0.3s ease;
  transition: max-width 0.3s ease;
  cursor: pointer;
}
  .ExitPreviewMode_root__s5AMV:hover {
    max-width: 100%;
  }
  .ExitPreviewMode_root__s5AMV span {
    white-space: nowrap;
  }
  .ExitPreviewMode_root__s5AMV svg {
    display: block;
    width: 20px;
    min-width: 20px;
    height: 20px;
  }@media (min-width: 600px) {
    }@media (min-width: 600px) {
    }@media (min-width: 600px) {
    }

@font-face {
font-family: '__ABCMonumentGrotesk_8b0e41';
src: url(/_next/static/media/574305e959cbb17b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__ABCMonumentGrotesk_8b0e41';
src: url(/_next/static/media/5637699ba7df806d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}@font-face {font-family: '__ABCMonumentGrotesk_Fallback_8b0e41';src: local("Arial");ascent-override: 101.13%;descent-override: 37.58%;line-gap-override: 0.00%;size-adjust: 99.78%
}.__className_8b0e41 {font-family: '__ABCMonumentGrotesk_8b0e41', '__ABCMonumentGrotesk_Fallback_8b0e41'
}.__variable_8b0e41 {--font-monument: '__ABCMonumentGrotesk_8b0e41', '__ABCMonumentGrotesk_Fallback_8b0e41'
}

:root {
  --hero-aspect: 5 / 6;
}
  @media (min-width: 600px) {:root {
    --hero-aspect: 16 / 9
}
  }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  overflow: auto;
  pointer-events: auto !important;
  margin: 0;
  background: var(--color-background);

  /* Hubspot Cookie Consent - Set body overflow: hidden when cookie consent is displaying */
  /* Commented out for now since Google Chrome extension "Still dont care about cookies" is visually hiding the popup,
  so the end user cannot decline or accept the cookie consent popup. 
  This way the popup never gets .hs-hidden, and the whole page has scroll lock.
  */

  /* &:has(#hs-eu-cookie-confirmation:not(.hs-hidden)) {
    overflow: hidden;

    &::after {
      z-index: var(--z-overlay);
      width: 100%;
      height: 100%;
      transition: background 300ms;
      background: rgba(0, 0, 0, 0.7);
    }

    & #navbarWrapper {
      z-index: var(--z-behind);
    }
  } */
}

body::after {
    content: "";
    width: 0;
    height: 0;
    position: fixed;
    top: 0;
    left: 0;
    -webkit-transition:
      background 300ms, width 0ms 150ms, height 0ms 150ms;
    transition:
      background 300ms, width 0ms 150ms, height 0ms 150ms;
    background: rgba(0, 0, 0, 0);
  }

body.scroll-locked {
    overflow: hidden;
  }

body.scroll-locked::after, body.overlay::after {
      z-index: var(--z-overlay);
      width: 100%;
      height: 100%;
      -webkit-transition: background 300ms;
      transition: background 300ms;
      background: rgba(0, 0, 0, 0.7);
    }

html {
  scrollbar-gutter: stable;
}

#coiOverlay {
  z-index: 999999;
}

@media (min-width: 600px) {
    }

@media (min-width: 600px) {
    }

@media (min-width: 600px) {
    }

/* Font scale */

/*
*** DO NOT USE THESE VARIABLES IN CSS FILES!
** These are used to create tokens in ./tokens.css
** Always extend tokens when applying styles to text
*/
@import url("https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,400..700;1,400..700&display=swap");
:root {
  --font-monument: "Instrument Sans", sans-serif;
  /* Font size litterals */
  --font-size-2xs: 11px;
  --font-size-xs: 12px;
  --font-size-s: 14px;
  --font-size-m: 16px;
  --font-size-l: 18px;
  --font-size-xl: 20px;
  --font-size-2xl: 22px;
  --font-size-3xl: 24px;
  --font-size-4xl: 26px;
  --font-size-5xl: 28px;
  --font-size-6xl: 32px;
  --font-size-7xl: 40px;
  --font-size-8xl: 48px;
  --font-size-9xl: 56px;
  --font-size-10xl: 64px;
  --font-size-11xl: 70px;
  --font-size-12xl: 110px;
  --font-size-13xl: 130px;
  --font-size-hero: 55px;

  /* dynamic sizes - comment out to disable 
  --font-size-rest1: clamp(13px, calc(13 / 1200 * 100vw), 100vw);

  --font-size-2xs: clamp(11px, calc(11 / 1200 * 100vw), 100vw);
  --font-size-xs: clamp(12px, calc(12 / 1200 * 100vw), 100vw);
  --font-size-s: clamp(14px, calc(14 / 1200 * 100vw), 100vw);
  --font-size-m: clamp(16px, calc(16 / 1200 * 100vw), 100vw);
  --font-size-l: clamp(18px, calc(18 / 1200 * 100vw), 100vw);
  --font-size-xl: clamp(20px, calc(20 / 1200 * 100vw), 100vw);
  --font-size-2xl: clamp(22px, calc(22 / 1200 * 100vw), 100vw);
  --font-size-3xl: clamp(24px, calc(24 / 1200 * 100vw), 100vw);
  --font-size-4xl: clamp(26px, calc(26 / 1200 * 100vw), 100vw);
  --font-size-5xl: clamp(28px, calc(28 / 1200 * 100vw), 100vw);
  --font-size-6xl: clamp(32px, calc(32 / 1200 * 100vw), 100vw);
  --font-size-7xl: clamp(40px, calc(40 / 1200 * 100vw), 100vw);
  --font-size-8xl: clamp(48px, calc(48 / 1200 * 100vw), 100vw);
  --font-size-9xl: clamp(56px, calc(56 / 1200 * 100vw), 100vw);
  ------------- */

  /* Font token variables */
  --headline1-size: var(--font-size-8xl);
  --headline2-size: var(--font-size-2xl);

  --subtitle1-size: var(--font-size-3xl);
  --subtitle2-size: var(--font-size-m);
  --subtitle3-size: var(--font-size-xs);

  --body1-size: var(--font-size-l);
  --body2-size: var(--font-size-s);
  --caption1-size: var(--font-size-xs);

  --filter-button-size: var(--font-size-xs);

  --hero-size: var(--font-size-hero);
  --explore-size: var(--font-size-2xl); /*TBD*/
  --byline-size: var(--font-size-s);

  --button-size: var(--font-size-s);
  --label-size: var(--font-size-2xs);
}
@media (min-width: 1400px) {
:root {
    --headline1-size: var(--font-size-11xl)
}
  }
@media (min-width: 600px) {
:root {
    --headline2-size: var(--font-size-4xl);

    --body1-size: var(--font-size-3xl);

    --filter-button-size: var(--font-size-m);

    --hero-size: var(--font-size-13xl);
    --explore-size: var(--font-size-2xl)
}
  }

body {
      font-family: "Instrument Sans", sans-serif;
      font-family: var(--font-monument);
      font-size: 14px;
      font-size: var(--body2-size);
      line-height: 1.5;
      font-weight: 400;
      text-transform: none;
}

p,
h1,
h2,
h3 {
  margin: 0;
}

.caption,
.preamble {
      font-family: "Instrument Sans", sans-serif;
      font-family: var(--font-monument);
      font-size: 12px;
      font-size: var(--caption1-size);
      line-height: 1.5;
      font-weight: 400;
      text-transform: none;
}

h1,
.h1,
.heading1 {
      font-family: "Instrument Sans", sans-serif;
      font-family: var(--font-monument);
      font-size: 48px;
      font-size: var(--headline1-size);
      line-height: 1.2;
      font-weight: 500;
      text-transform: none;
}

h2,
.h2,
.heading2 {
      font-family: "Instrument Sans", sans-serif;
      font-family: var(--font-monument);
      font-size: 22px;
      font-size: var(--headline2-size);
      line-height: 1.3;
      font-weight: 400;
      text-transform: uppercase;
}

.title {
      font-family: "Instrument Sans", sans-serif;
      font-family: var(--font-monument);
      font-size: 48px;
      font-size: var(--headline1-size);
      line-height: 1.2;
      font-weight: 500;
      text-transform: none;
}

@media (min-width: 600px) {
    }

@media (min-width: 600px) {
    }

@media (min-width: 600px) {
    }

/* tablet - small */ /* desktop - small */ /* desktop - large */

/* Try not to use this */
@media (min-width: 600px) {
    }
@media (min-width: 600px) {
    }
@media (min-width: 600px) {
    }

:root {
  /* ------- Base colors ------- */
  --color-white: #fff;
  --color-black: #161616;
  --color-dark: var(--color-black);
  --color-light: var(--color-white);
  --color-base: var(--color-white);
  --color-red: #cc2500; /* For now */

  /* greyscale */

  --neutral-11: #b4af9d; /* Adding this here for out of stock sizes PDP */
  --neutral-10: #e0dccd; /* Adding this here for diasbled add-to-cart-button PDP, background for LocationModal and PLP columns buttons, footer background*/

  --neutral-9: #161616;
  --neutral-8: #272727;
  --neutral-7: #3a3a3a;
  --neutral-6: #525252;
  --neutral-5: #707070;
  --neutral-4: #949494;
  --neutral-3: #e3e3e3;
  --neutral-2: #f9f9f2;
  --neutral-1: #fff;

  /* Temporary neutral color to match design */
  --neutral-5-1: #7c796d;
  --neutral-6-1: #404039;
  --neutral-6-2: #757266; /* Dimmed */

  --color-primary: var(--color-dark);

  /* text */
  --color-text: var(--neutral-9);
  --color-text-secondary: var(--neutral-6);
  --color-text-disabled: var(--neutral-9);

  /* decorators */
  --color-divider: var(--color-dark);
  --color-divider-inactive: var(--neutral-3);
  --color-divider-active: var(--neutral-5-1);

  /* Input */
  --color-input-border: var(--neutral-5);
  --color-input-focus: var(--color-dark);

  /* button */
  --color-button-disabled: var(--neutral-10);
  --color-backdrop: rgba(0, 0, 0, 0.7);

  /* dialog overlay */
  --dialog-overlay-background: rgba(0, 0, 0, 0.3);
}

body {
  background: #f9f9f2;
  background: var(--neutral-2);
}

@media (min-width: 600px) {
    }

@media (min-width: 600px) {
    }

@media (min-width: 600px) {
    }

/*
 * data-layout=page for full width elements
 * data-layout=section for inset elements
 * data-blok on the outer wrapper of all bloks
 */

:root {
  --section-margin: 80px;
  --content-margin: 50px;
  --stacked-margin: var(--margin-spacing);

  --page-inset: var(--spacing-16); /* General site horizontal margins */
  --smart-page-inset: var(--page-inset);

  --page-inset-width: min(
    var(--page-width),
    calc(100% - (var(--page-inset) * 2))
  );
  --section-inset-width: min(
    var(--section-width),
    calc(100% - (var(--page-inset) * 2))
  );
  --content-inset-width: var(--page-inset-width);
  --text-inset-width: min(
    var(--text-width),
    calc(100% - (var(--page-inset) * 2))
  );
}

@media (min-width: 600px) {

:root {
    --section-margin: 120px;
    --smart-page-inset: max(
      calc((100vw - var(--page-width)) / 2 - 8px),
      var(--page-inset)
    );
}
  }

@media (min-width: 1000px) {

:root {
    --content-inset-width: calc(var(--page-inset-width) * 0.9);
}
  }

:root {

  --column: calc(100% / 12);
}

[data-layout="page"] {
  max-width: min(
    var(--page-width),
    calc(100% - (var(--spacing-16) * 2))
  );
  max-width: var(--page-inset-width);
  width: 100%;
  margin: 0 auto;
}

[data-layout="page"] > [data-layout="section"] {
    max-width: var(--section-width);
  }

.modal [data-layout="page"] {
    max-width: 100%;
  }

[data-layout="page"] [data-layout="page"] {
  max-width: var(--page-width);
}

[data-layout="content"] {
  max-width: min(
    var(--page-width),
    calc(100% - (var(--spacing-16) * 2))
  );
  max-width: var(--content-inset-width);
  margin: 0 auto;
}

[data-layout="text"] {
  max-width: min(
    var(--text-width),
    calc(100% - (var(--spacing-16) * 2))
  );
  max-width: var(--text-inset-width);
  margin: 0 auto;
}

[data-layout="full"] {
  max-width: 100%;
  margin: 0 auto;
}

[data-blok] + [data-blok] {
  margin-top: 80px;
  margin-top: var(--section-margin);
}

[data-blok][data-editorial="true"] + [data-blok][data-editorial="true"] {
  margin-top: 50px;
  margin-top: var(--content-margin);
}

.navigation-bar + [data-blok]:not([data-blok-background][data-layout="full"]) {
  margin-top: 80px;
  margin-top: var(--section-margin);
}

.inset {
  padding-left: var(--spacing-16);
  padding-left: var(--page-inset);
  padding-right: var(--spacing-16);
  padding-right: var(--page-inset);
}

:where(picture) {
  max-width: 100%;
  height: auto;
  display: block;

  /* & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  } */
}

.divider {
  border-bottom: 1px solid var(--color-divider);
}

.layout-simple {
  --grid-left-indent: 6 span;
  --grid-left-indent-small: 6 span;

  --grid-right-indent: 6 / 12;
  --grid-right-indent-small: 6 / 12;

  --section-margin: 80px;
}

@media (min-width: 600px) {
    }

@media (min-width: 600px) {
    }

@media (min-width: 600px) {
    }

/* general input style */

:root {
  --input-height: var(--spacing-48);
}

@media (min-width: 600px) {
    }

@media (min-width: 600px) {
    }

@media (min-width: 600px) {
    }

@media (prefers-reduced-motion: no-preference) {
  :root {
    /* scale */
    --scale: 1.05;
    --scale-large: 1.2;
    --scale-transition: all ease 0.15s;
    --scroll-transition: 800ms;

    /* underline transition */
    --underline-transition: width 0.15s ease;

    /* fade */
    --fade-transition: opacity 0.15s ease;
  }

  /* globals, overwrite to turn off */
  [data-blok][data-animated="fadeY"] {
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
    -webkit-transition: all var(--scroll-transition);
    transition: all var(--scroll-transition);
    opacity: 0;
  }

    @media (min-width: 1000px) {
  [data-blok][data-animated="fadeY"] {
      -webkit-transform: translateY(1.5vw);
              transform: translateY(1.5vw)
  }
    }

    [data-blok][data-animated="fadeY"][data-view="true"] {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    @media (prefers-reduced-motion) {
  [data-blok][data-animated="fadeY"] {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1
  }
    }
  [data-blok][data-animated="fadeX"] {
    -webkit-transform: translateX(-40px);
            transform: translateX(-40px);
    -webkit-transition: all var(--scroll-transition);
    transition: all var(--scroll-transition);
    opacity: 0;
  }

    @media (min-width: 1000px) {
  [data-blok][data-animated="fadeX"] {
      -webkit-transform: translateX(-1.5vw);
              transform: translateX(-1.5vw)
  }
    }

    [data-blok][data-animated="fadeX"][data-view="true"] {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    @media (prefers-reduced-motion) {
  [data-blok][data-animated="fadeX"] {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1
  }
    }
  [data-blok][data-animated="fadeXinvert"] {
    -webkit-transform: translateX(40px);
            transform: translateX(40px);
    -webkit-transition: all var(--scroll-transition);
    transition: all var(--scroll-transition);
    opacity: 0;
  }

    @media (min-width: 1000px) {
  [data-blok][data-animated="fadeXinvert"] {
      -webkit-transform: translateX(1.5vw);
              transform: translateX(1.5vw)
  }
    }

    [data-blok][data-animated="fadeXinvert"][data-view="true"] {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    @media (prefers-reduced-motion) {
  [data-blok][data-animated="fadeXinvert"] {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1
  }
    }

  /* ---  */

  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @-webkit-keyframes contentShow {
    from {
      opacity: 0;
      -webkit-transform: translateX(200%);
              transform: translateX(200%);
    }
    to {
      opacity: 1;
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
    }
  }

  @keyframes contentShow {
    from {
      opacity: 0;
      -webkit-transform: translateX(200%);
              transform: translateX(200%);
    }
    to {
      opacity: 1;
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
    }
  }

  @media (prefers-reduced-motion) {
    * {
      opacity: 1 !important;
      -webkit-transform: translateY(0) !important;
              transform: translateY(0) !important;
    }
  }
}

.underline-hover {
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  -webkit-animation: l5 1s infinite linear alternate;
          animation: l5 1s infinite linear alternate;
  margin-left: var(--page-inset);
}
@-webkit-keyframes l5 {
  0% {
    -webkit-box-shadow:
      20px 0 var(--color-primary),
      -20px 0 var(--color-primary-light);
            box-shadow:
      20px 0 var(--color-primary),
      -20px 0 var(--color-primary-light);
    background: var(--color-primary);
  }
  33% {
    -webkit-box-shadow:
      20px 0 var(--color-primary),
      -20px 0 var(--color-primary-light);
            box-shadow:
      20px 0 var(--color-primary),
      -20px 0 var(--color-primary-light);
    background: var(--color-primary-light);
  }
  66% {
    -webkit-box-shadow:
      20px 0 var(--color-primary-light),
      -20px 0 var(--color-primary);
            box-shadow:
      20px 0 var(--color-primary-light),
      -20px 0 var(--color-primary);
    background: var(--color-primary-light);
  }
  100% {
    -webkit-box-shadow:
      20px 0 var(--color-primary-light),
      -20px 0 var(--color-primary);
            box-shadow:
      20px 0 var(--color-primary-light),
      -20px 0 var(--color-primary);
    background: var(--color-primary);
  }
}
@keyframes l5 {
  0% {
    -webkit-box-shadow:
      20px 0 var(--color-primary),
      -20px 0 var(--color-primary-light);
            box-shadow:
      20px 0 var(--color-primary),
      -20px 0 var(--color-primary-light);
    background: var(--color-primary);
  }
  33% {
    -webkit-box-shadow:
      20px 0 var(--color-primary),
      -20px 0 var(--color-primary-light);
            box-shadow:
      20px 0 var(--color-primary),
      -20px 0 var(--color-primary-light);
    background: var(--color-primary-light);
  }
  66% {
    -webkit-box-shadow:
      20px 0 var(--color-primary-light),
      -20px 0 var(--color-primary);
            box-shadow:
      20px 0 var(--color-primary-light),
      -20px 0 var(--color-primary);
    background: var(--color-primary-light);
  }
  100% {
    -webkit-box-shadow:
      20px 0 var(--color-primary-light),
      -20px 0 var(--color-primary);
            box-shadow:
      20px 0 var(--color-primary-light),
      -20px 0 var(--color-primary);
    background: var(--color-primary);
  }
}

@-webkit-keyframes skeleton-loading {
  0% {
    background-color: var(--neutral-2);
  }

  100% {
    background-color: var(--neutral-3);
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: var(--neutral-2);
  }

  100% {
    background-color: var(--neutral-3);
  }
}

@media (min-width: 600px) {
    }

@media (min-width: 600px) {
    }

@media (min-width: 600px) {
    }

:root {
  /* Spacing sets */

  --base: 8px;
  --spacing-4: calc(var(--base) / 2);
  --spacing-6: calc(var(--base) * 0.75);
  --spacing-8: var(--base);
  --spacing-10: calc(var(--base) * 1.25);
  --spacing-12: calc(var(--base) * 1.5);
  --spacing-14: calc(var(--base) * 1.75);
  --spacing-16: calc(var(--base) * 2);
  --spacing-20: calc(var(--base) * 2.5);
  --spacing-24: calc(var(--base) * 3);
  --spacing-26: calc(var(--base) * 3.25);
  --spacing-32: calc(var(--base) * 4);
  --spacing-40: calc(var(--base) * 5);
  --spacing-48: calc(var(--base) * 6);
  --spacing-52: calc(var(--base) * 6.5);
  --spacing-64: calc(var(--base) * 8);
  --spacing-70: calc(var(--base) * 8.75);
  --spacing-80: calc(var(--base) * 10);
  --spacing-96: calc(var(--base) * 12);
  --spacing-128: calc(var(--base) * 16);
  --spacing-144: calc(var(--base) * 18);
  --spacing-176: calc(var(--base) * 22);

  --base-spacing: var(--base);
  /* vertical distance between blocks and sections */
  --section-spacing: var(--spacing-48);
  --margin-spacing: var(--spacing-16);
  /* used for gaps between columns in flex or grid containers */
  --column-gap: var(--spacing-sm);
  --row-gap: var(--spacing-sm);
  --paragraph-width: 770px;

  --spacing-3xs: var(--spacing-8);
  --spacing-2xs: var(--spacing-12);
  --spacing-xs: var(--spacing-16);
  --spacing-sm: var(--spacing-24);
  --spacing-md: var(--spacing-24);
  --spacing-lg: var(--spacing-40);
  --spacing-2xl: var(--spacing-144);
}

  @media (min-width: 600px) {:root {
    --row-gap: var(--spacing-xs);
    --column-gap: var(--spacing-xs);

    --spacing-md: var(--spacing-40);
    --spacing-lg: var(--spacing-80);
    --spacing-xl: var(--spacing-96);

    --margin-spacing: var(--spacing-24)
}
  }@media (min-width: 600px) {
    }@media (min-width: 600px) {
    }@media (min-width: 600px) {
    }

/* 
 * .sr-only
 * 
 * good way to make something accesible to screen readers, but not visible 
*/

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

/* Only show on desktop */
@media (max-width: 599px) {
.desktop {
    display: none
}
  }

/* Only show on mobile */
@media (min-width: 600px) {
.mobile {
    display: none
}
  }
@media (min-width: 600px) {
    }
@media (min-width: 600px) {
    }
@media (min-width: 600px) {
    }

:root {
  /* page size and layout variables */
  --page-width: 4800px;
  --section-width: 100%;
  --text-width: 806px;

  /* icon variables */
  --icon-xs: var(--spacing-12);
  --icon-sm: var(--spacing-16);
  --icon-md: var(--spacing-24);
  --icon-lg: var(--spacing-32);

  /* pdp add to favourite + add to cart button */
  --pdp-button-border: #d5d0be;
  --pdp-button-height: 37px;
  --pdp-button-color-inactive: var(--neutral-10);
  --pdp-button-size-inactive: var(--neutral-11);

  /* buttons height in PLP filter and sort-by modal */
  --plp-button-height: 44px;

  /* tags */
  --tags-background-color: var(--neutral-2);
  --color-inactive-tag: var(--neutral-6-2);

  /* radio buttons ::before and ::after elements */
  --radio-outer-size: 24px;
  --radio-inner-size: calc(var(--radio-outer-size) - 6px);

  /* swatches */
  --swatch-outer-size: 18px;
  --swatch-inner-size: calc(var(--swatch-outer-size) - 6px);
  --swatch-border: 1px solid #ddd;
  --swatch-default-color: var(--neutral-6);

  /* z-index variables */
  --z-overlay: 100;
  --z-sticky: 50;
  --z-behind: -1;
  --z-above: 1;

  /* header variables */
  --snackbar-height: 32px;
  --header-offset-mobile: 10px;
  --header-height-scroll: 48px;
  --header-height: 63px;
  --header-transition-speed: 250ms;
  --_home-header-padding: 0px;
  /*
  @media (--small) {
    --header-height: 72px;
  }

  @media (--large) {
    --header-height: 92px;
  }
  */
  /* accordion variables */
  --accordion-spacing: var(--spacing-12);
  --border-radius-xs: 4px;

  --header-extra-click-area: 25px;
}@media (min-width: 600px) {
    }@media (min-width: 600px) {
    }@media (min-width: 600px) {
    }

/* adyen */
.adyen-drop-in-container .adyen-checkout__payment-method {
    border: 0;
  }
.adyen-drop-in-container .adyen-checkout__payment-method--selected {
    border: 0;
    background: transparent;
  }
.adyen-drop-in-container .adyen-checkout__button {
    background: var(--color-primary);
  }
.adyen-drop-in-container .adyen-checkout__payment-method__radio--selected {
    background: var(--color-primary);
  }
.adyen-drop-in-container .adyen-checkout__payment-method__radio {
    left: 0;
  }
.adyen-drop-in-container .adyen-checkout__payment-method__details {
    padding: 0;
  }
.adyen-drop-in-container .adyen-checkout__payment-method__header {
    padding-left: 32px;
  }
.adyen-drop-in-container .adyen-checkout__payment-method {
    padding-left: 1rem;
  }
@media (min-width: 600px) {
    }
@media (min-width: 600px) {
    }
@media (min-width: 600px) {
    }

body {
  --arrow-size: 24px;
}

/* body to increase specificity */

body .swiper {
    padding-bottom: calc(var(--spacing-16) + var(--arrow-size));
  }

body .swiper-button-prev,
  body .swiper-button-next {
    position: absolute;
    background-color: var(--color-tertiary);
    border-radius: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: var(--arrow-size);
    height: var(--arrow-size);
    --swiper-navigation-size: 14px;
    color: var(--color-primary);
    -webkit-transition: opacity 250ms;
    transition: opacity 250ms;
    top: auto;
    bottom: 0;
  }

body .swiper-button-prev:hover, body .swiper-button-next:hover {
      background: var(--neutral-2);
    }

body .swiper-button-prev::after, body .swiper-button-next::after {
      content: "";
      background-image: url("/images/ui-more-right.svg");
      background-size: 16px;
      width: 16px;
      height: 16px;
      display: block;
    }

body .swiper-button-next {
    right: 0;
  }

body .swiper-button-prev {
    left: auto;
    right: calc(0px + var(--arrow-size) + var(--spacing-md));
  }

body .swiper-button-prev::after {
      background-image: url("/images/ui-more-left.svg");
    }

body .swiper-button-next.swiper-button-disabled,
  body .swiper-button-prev.swiper-button-disabled,
  body .swiper:hover .swiper-button-next.swiper-button-disabled,
  body .swiper:hover .swiper-button-prev.swiper-button-disabled {
    opacity: 0.5;
  }

body .swiper-pagination-bullets {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    left: calc(var(--arrow-size) + var(--spacing-md));
    bottom: calc(var(--arrow-size) / 2 - 2px);
    width: calc(100% - var(--arrow-size) * 3 - var(--spacing-md) * 3);
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

body .swiper-pagination-bullet {
    width: 100%;
    height: 2px;
    background: hsl(0, 0%, 89%);
    display: block;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
  }

body .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background: hsl(0, 0%, 9%);
    }

body .swiper-slide {
    opactiy: 0;
    -webkit-transition: all 800ms;
    transition: all 800ms;
    margin-left: 1px;
    margin-right: 1px;
  }

body .swiper-slide.swiper-slide-active {
      opacity: 1;
    }

body .swiperZoom {
    padding: 0 !important;
    --controler-width: min(543px, calc(100vw - var(--page-inset) * 2));
    --controler-padding: 20px;
    --controler-bottom: 20px;
  }

body .swiperZoom .swiper-pagination-bullets {
      background: #fff;
      border-radius: 200px;
      padding: var(--controler-padding) 152px var(--controler-padding) 86px;
      bottom: var(--controler-bottom);
      z-index: 97;
      width: calc(100vw - var(--page-inset) * 2);
      max-width: var(--controler-width);
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
    }

body .swiperZoom .swiper-button-prev,
    body .swiperZoom .swiper-button-next {
      bottom: calc(var(--controler-bottom) + var(--controler-padding) / 2);
      left: 50%;
      right: auto;
      z-index: 98;
      -webkit-transform: translateX(
        calc(var(--controler-width) / 2 - 24px - var(--arrow-size))
      );
              transform: translateX(
        calc(var(--controler-width) / 2 - 24px - var(--arrow-size))
      );
    }

body .swiperZoom .swiper-button-prev {
      -webkit-transform: translateX(
        calc(var(--controler-width) / 2 - 24px - var(--arrow-size) * 3)
      );
              transform: translateX(
        calc(var(--controler-width) / 2 - 24px - var(--arrow-size) * 3)
      );
    }

body .swiperZoom .swiperCount {
      bottom: calc(var(--controler-bottom) + var(--controler-padding) / 2);
      left: 50%;
      z-index: 98;
      -webkit-transform: translateX(calc((0px - var(--controler-width)) / 2 + 24px));
              transform: translateX(calc((0px - var(--controler-width)) / 2 + 24px));
    }

@media (--md) {

body {
    --controler-bottom: 40px
}
  }

@media (min-width: 600px) {
    }

@media (min-width: 600px) {
    }

@media (min-width: 600px) {
    }

/*
 * External CSS for BLÆST
*/
@media (min-width: 600px) {
    }
@media (min-width: 600px) {
    }
@media (min-width: 600px) {
    }

.dialog-overlay {
  background-color: var(--dialog-overlay-background);
  position: fixed;
  z-index: 900;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-animation: fadeIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
          animation: fadeIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.dialog-content {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  -webkit-animation: contentShow 300ms cubic-bezier(0.16, 1, 0.3, 1);
          animation: contentShow 300ms cubic-bezier(0.16, 1, 0.3, 1);
}

@media (min-width: 600px) {
    }

@media (min-width: 600px) {
    }

@media (min-width: 600px) {
    }

/*
 * Common CSS for BLÆST
*/ /* Body styles and reset */ /* Typography variables and tokens + common CSS for typographical elements */ /* Media query definitions */ /* Color variables and general color component theming */ /* Container and layout system, responsible for component and site widths and margins */ /* Base style for input elements */ /* Keyframe animations and animation helpers */ /* Base style for spacings */ /* helper classes */ /* global variables classes */ /* 3rd party style overrides */ /* Base style for dialog elements */ @media (min-width: 600px) {
    } @media (min-width: 600px) {
    } @media (min-width: 600px) {
    }

