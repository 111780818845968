:root {
  /* ------- Base colors ------- */
  --color-white: #fff;
  --color-black: #161616;
  --color-dark: var(--color-black);
  --color-light: var(--color-white);
  --color-base: var(--color-white);
  --color-red: #cc2500; /* For now */

  /* greyscale */

  --neutral-11: #b4af9d; /* Adding this here for out of stock sizes PDP */
  --neutral-10: #e0dccd; /* Adding this here for diasbled add-to-cart-button PDP, background for LocationModal and PLP columns buttons, footer background*/

  --neutral-9: #161616;
  --neutral-8: #272727;
  --neutral-7: #3a3a3a;
  --neutral-6: #525252;
  --neutral-5: #707070;
  --neutral-4: #949494;
  --neutral-3: #e3e3e3;
  --neutral-2: #f9f9f2;
  --neutral-1: #fff;

  /* Temporary neutral color to match design */
  --neutral-5-1: #7c796d;
  --neutral-6-1: #404039;
  --neutral-6-2: #757266; /* Dimmed */

  --color-primary: var(--color-dark);

  /* text */
  --color-text: var(--neutral-9);
  --color-text-secondary: var(--neutral-6);
  --color-text-disabled: var(--neutral-9);

  /* decorators */
  --color-divider: var(--color-dark);
  --color-divider-inactive: var(--neutral-3);
  --color-divider-active: var(--neutral-5-1);

  /* Input */
  --color-input-border: var(--neutral-5);
  --color-input-focus: var(--color-dark);

  /* button */
  --color-button-disabled: var(--neutral-10);
  --color-backdrop: rgba(0, 0, 0, 0.7);

  /* dialog overlay */
  --dialog-overlay-background: rgba(0, 0, 0, 0.3);
}

body {
  background: var(--neutral-2);
}
