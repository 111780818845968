:root {
  /* Spacing sets */

  --base: 8px;
  --spacing-4: calc(var(--base) / 2);
  --spacing-6: calc(var(--base) * 0.75);
  --spacing-8: var(--base);
  --spacing-10: calc(var(--base) * 1.25);
  --spacing-12: calc(var(--base) * 1.5);
  --spacing-14: calc(var(--base) * 1.75);
  --spacing-16: calc(var(--base) * 2);
  --spacing-20: calc(var(--base) * 2.5);
  --spacing-24: calc(var(--base) * 3);
  --spacing-26: calc(var(--base) * 3.25);
  --spacing-32: calc(var(--base) * 4);
  --spacing-40: calc(var(--base) * 5);
  --spacing-48: calc(var(--base) * 6);
  --spacing-52: calc(var(--base) * 6.5);
  --spacing-64: calc(var(--base) * 8);
  --spacing-70: calc(var(--base) * 8.75);
  --spacing-80: calc(var(--base) * 10);
  --spacing-96: calc(var(--base) * 12);
  --spacing-128: calc(var(--base) * 16);
  --spacing-144: calc(var(--base) * 18);
  --spacing-176: calc(var(--base) * 22);

  --base-spacing: var(--base);
  /* vertical distance between blocks and sections */
  --section-spacing: var(--spacing-48);
  --margin-spacing: var(--spacing-16);
  /* used for gaps between columns in flex or grid containers */
  --column-gap: var(--spacing-sm);
  --row-gap: var(--spacing-sm);
  --paragraph-width: 770px;

  --spacing-3xs: var(--spacing-8);
  --spacing-2xs: var(--spacing-12);
  --spacing-xs: var(--spacing-16);
  --spacing-sm: var(--spacing-24);
  --spacing-md: var(--spacing-24);
  --spacing-lg: var(--spacing-40);
  --spacing-2xl: var(--spacing-144);

  @media (--small) {
    --row-gap: var(--spacing-xs);
    --column-gap: var(--spacing-xs);

    --spacing-md: var(--spacing-40);
    --spacing-lg: var(--spacing-80);
    --spacing-xl: var(--spacing-96);

    --margin-spacing: var(--spacing-24);
  }
}
